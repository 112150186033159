<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/XXPX'}">学习培训</el-breadcrumb-item>
      <el-breadcrumb-item v-if="isShow" :to="{ path: '/ZCZB' }">质控指标</el-breadcrumb-item>
      <el-breadcrumb-item>{{ Name }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap" v-if="list.length !== 0">
      <div class="carditem" v-for="t in list" :key="t.Link">
        <VideoCard :content="t" @play-click="playClick" />
      </div>
    </div>
    <div v-else>
      <el-empty :image-size="200"></el-empty>
    </div>

  </div>
</template>

<script>
import { GetQCVideos } from '@/api'
import VideoCard from '@/components/videoCard/videoCard'

export default {
  name: "ZCZBDetail",
  data() {
    return {
      Id: this.$route.params.Id || "",
      Name: this.$route.params.Name || "",
      list:[],
    }
  },
  components: {
    VideoCard,
  },
  computed: {
    isShow() {
      const targetNames = ['经验交流', '共识学习'];
      const hasMatch = targetNames.some(name => this.Name == name);
      return !hasMatch;
    }
  },
  methods: {
    async GetQCVideosAsync() {
      const r = await GetQCVideos({
        QCVideoMainId: this.Id
      })
      if (r.code == 1) {
        this.list = r.data
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
    playClick(content) {
      sessionStorage.videoccLink = content.Link
      this.$router.push({
        path: `/VideoLesson/${content.Id}`,
      })
    }
  },
  created() {
    this.GetQCVideosAsync()
   },
}
</script>

<style scoped>
.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  margin-top: 20px;
}

.carditem {
  padding: 10px 25px;
}
</style>