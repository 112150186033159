<template>
  <div class="card">
    <el-card :body-style="{ padding: '0px' }">
      <img :src="content.ThumLink" class="image" />
      <div style="padding: 14px">
        <span class="title-span">{{ content.Title }}</span>
        <div class="bottom">
          <div class="info-box">
            <time class="time">讲师：{{ content.DoctorName }}</time>
            <div class="view-box">
              <i class="el-icon-view view-icon"> 浏览量：</i><span>{{ content.viewCount }}</span>
            </div>
          </div>
          <div class="btn-box">
            <el-button type="text" class="button" @click="onClick">查看</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "VideoCard",
  props: {
    content: {
      type: Object,
      default() {
        return {
          DoctorName: "",
          Hospital: "",
          Link: "",
          ThumLink: "",
          Title: "",
          Id: "",
          viewCount: "",
        };
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onClick() {
      this.$emit('play-click', this.content)
    }
  }
};
</script>

<style scoped>
.card {
  width: 300px;
}

.time {
  font-size: 13px;
}

.bottom {
  margin-top: 15px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 150px;
  display: block;
}


.info-box {}

.btn-box {}

.view-box {
  font-size: 13px;
  color: #999;
  margin-top: 10px;
}
.title-span {
  height: 42px;
  display: block;
}
</style>